<template>
  <div class="modal-backdrop" @click="close">
    <transition name="fade">
      <div
        class="modal"
        v-if="show"
        v-bind:style="{ background: computedModalBackgroundColor }"
      >
        <header class="modal-header">
          <button type="button" class="btn-close" @click="close">x</button>
        </header>

        <section class="modal-body">
          <slot name="body" v-if="notificationType == 'NEW_REQUEST'">
            New request !
          </slot>
          <slot name="body" v-else-if="notificationType == 'REQUEST_CANCELED'">
            Request canceled !
          </slot>
          <slot name="body" v-else-if="notificationType == 'REQUEST_REFUSED'">
            Request refused !
          </slot>
        </section>
      </div>
    </transition>
  </div>
</template>
<script>
  const alertSound = require("@/assets/alert.mp3");
  const newRequestSound = require("@/assets/notification.mp3");

  export default {
    props: {
      notificationType: {
        type: String,
        default: "NONE",
      },
      bookingId: {
        type: String,
        default: "",
      },
    },
    mounted() {
      if (this.notificationType != "NONE") {
        setTimeout(() => (this.show = true), 1);
        setTimeout(() => this.playAudio(1.0), 1);
        this.soundInterval = setInterval(() => this.playAudio(1.6), 20000);
        setTimeout(() => this.playAnimation(), 5010);
      }
    },
    destroyed() {
      if (this.animationInterval != undefined) {
        clearInterval(this.animationInterval);
      }
      if (this.backgroundColorInterval != undefined) {
        clearInterval(this.backgroundColorInterval);
      }
      if (this.soundInterval != undefined) {
        clearInterval(this.soundInterval);
      }
    },
    data() {
      return {
        animationInterval: undefined,
        backgroundColorInterval: undefined,
        soundInterval: undefined,
        show: false,
        modalBackgroundColor: "#FF7347",
      };
    },
    computed: {
      computedModalBackgroundColor() {
        return this.modalBackgroundColor;
      },
    },
    name: "AlertModal",
    methods: {
      close() {
        this.setBookingNotificationToSeen();
        if (this.animationInterval != undefined) {
          clearInterval(this.animationInterval);
        }
        if (this.backgroundColorInterval != undefined) {
          clearInterval(this.backgroundColorInterval);
        }
        if (this.soundInterval != undefined) {
          clearInterval(this.soundInterval);
        }
        this.$emit("close");
      },
      changeModalBackground() {
        if (this.modalBackgroundColor == "#FFFFFF") {
          this.modalBackgroundColor = "#FF7347";
        } else {
          this.modalBackgroundColor = "#FFFFFF";
        }
      },
      playAudio(playBackRate) {
        if (this.notificationType == "NEW_REQUEST") {
          let note = new Audio(newRequestSound);
          note.addEventListener("canplaythrough", () => {
            note.playbackRate = playBackRate;
            note.play();
          });
        } else if (
          this.notificationType == "REQUEST_CANCELED" ||
          this.notificationType == "REQUEST_REFUSED"
        ) {
          let note = new Audio(alertSound);
          note.addEventListener("canplaythrough", () => {
            note.playbackRate = playBackRate;
            note.play();
          });
        }
      },
      restartAnimation() {
        this.animationInterval = setInterval(() => this.playAnimation(), 2400);
      },
      playAnimation() {
        if (this.animationInterval != null) {
          clearInterval(this.animationInterval);
        }
        this.backgroundColorInterval = setInterval(
          () => this.changeModalBackground(),
          400
        );
        setTimeout(() => clearInterval(this.backgroundColorInterval), 2500);
        setTimeout(() => this.restartAnimation(), 5000);
      },
      setBookingNotificationToSeen() {
        this.$http.patch("bookings/notification/update", {
          bookingId: this.bookingId,
        });
        if (this.$route.name == "taskRecharge") {
          this.$router.replace({ name: "listRecharge" });
        }
      },
    },
  };
</script>
<style>
  .modal-backdrop {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    border-radius: 10px;
    height: 80vh;
    width: calc(100vw - 100px);
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-body {
    position: relative;
    font-size: 40px;
    padding: 20px 40px;
  }

  @media (max-width: 500px) {
    .modal-body {
      position: relative;
      font-size: 20px;
      padding: 20px 20px;
    }
  }

  .btn-close {
    border: none;
    font-size: 30px;
    margin-left: calc(100vw - 130px);
    cursor: pointer;
    font-weight: bold;
    background: transparent;
  }

  .fade-enter-active {
    transition: opacity 5s ease-in-out;
  }

  .fade-enter-to {
    opacity: 1;
  }

  .fade-enter {
    opacity: 0.1;
  }
</style>
