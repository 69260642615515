import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayout from "@/layouts/default";
import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "listRecharge",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/listRecharge.vue"),
        meta: {
          title: "List Recharge",
          middleware: auth,
        },
      },
      {
        path: "request-recharge/:id",
        name: "requestRecharge",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/driver/requestRecharge.vue"
          ),
        meta: {
          title: "Request Recharge",
          middleware: auth,
        },
      },
      {
        path: "task-recharge/:id",
        name: "taskRecharge",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/teammember/task.vue"),
        meta: {
          title: "Request Recharge",
          middleware: auth,
        },
      },
      {
        path: "blocking",
        name: "blockingSlot",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/teammember/blocking.vue"
          ),
        meta: {
          title: "Blocking Slot",
          middleware: auth,
        },
      },
      {
        path: "/create-account",
        name: "createAccount",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/admin/createAccount.vue"
          ),
        meta: {
          title: "Create Account",
          middleware: [auth, admin],
        },
      },
      {
        path: "/edit-password",
        name: "editPassword",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/auth/editPassword.vue"
          ),
        meta: {
          title: "Edit Password",
          middleware: auth,
        },
      },
      {
        path: "/accounts-list",
        name: "accountsList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/admin/accountsList.vue"
          ),
        meta: {
          title: "Accounts List",
          middleware: [auth, admin],
        },
      },
      {
        path: "/parkings-list",
        name: "parkingsList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/admin/parkingsList.vue"
          ),
        meta: {
          title: "Parkings List",
          middleware: [auth, admin],
        },
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/auth/login.vue"),
  },
  {
    path: "/forgot-account",
    name: "forgotAccount",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/forgotAccount.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/passwordToken.vue"),
  },
  {
    path: "/policy",
    name: "policy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/policy.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});

export default router;
