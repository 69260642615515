import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import './styles/index.scss'
import axiosConfig from "./plugins/axios.js"
import store from './store'
import Vuelidate from 'vuelidate'
import moment from 'moment'
import { ROLE, STATUS } from './utils/constant'
Vue.prototype.$status = STATUS
Vue.prototype.$role = ROLE
Vue.config.productionTip = false
Vue.prototype.$http = axiosConfig;
Vue.prototype.$moment = moment
Vue.prototype.$localTime = function(type = '') {
  return this.$moment(new Date()).format(type)
}
Vue.prototype.$padZero = function(num) {
  return ("0" + num).slice(-2);
},
Vue.prototype.$isMobile = function () {
  return this.$vuetify.breakpoint.mobile;
}
new Vue({
  router,
  vuetify,
  store,
  Vuelidate,
  render: h => h(App)
}).$mount('#app')
