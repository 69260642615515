import { ROLE } from "@/utils/constant";

export default function admin({ next, to }) {
  const user = localStorage.getItem("user");
  if (user) {
    if (user.role === ROLE.DRIVER || user.role === ROLE.TEAM_MEMBER) {
      return next({ name: "listRecharge" });
    } else {
      return next();
    }
  }
  return next({ name: "listRecharge" });
}
