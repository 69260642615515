<template>
  <v-app>
    <component :is="layout">
      <router-view :layout.sync="layout" />
    </component>
    <AlertModal
      v-if="
        bookingNotification && bookingNotification.notificationType != 'NONE'
      "
      v-show="bookingNotification.notificationType != 'NONE'"
      :notification-type="bookingNotification.notificationType"
      :booking-id="bookingNotification.booking._id"
      @close="closeModal"
    />
    <v-toast ref="vToast"></v-toast>
    <v-popup ref="vPopup"></v-popup>
  </v-app>
</template>

<script>
  import AlertModal from "@/components/AlertModal.vue";
  import vToast from "@/components/Snackbar.vue";
  import vPopup from "@/components/Dialog.vue";
  import AuthLayout from "@/layouts/auth";
  import Vue from "vue";
  import { getRole } from "@/utils/user";

  export default {
    name: "App",
    components: { AuthLayout, vToast, vPopup, AlertModal },
    data() {
      return {
        bookingNotificationRequestInProgress: false,
        bookingNotification: undefined,
        layout: "AuthLayout",
        intervalBookingNotification: null,
      };
    },
    mounted() {
      this.checkNotification();
      Vue.prototype.$vToast = this.$refs.vToast;
      Vue.prototype.$vPopup = this.$refs.vPopup;
    },
    destroyed() {
      clearInterval(this.intervalBookingNotification);
    },
    watch: {
      $route: function (to) {
        if (to.name !== "requestRecharge") this.checkBooking();
        if (to.name !== "login" && to.name !== "forgotPassword" && to.name !== "forgotAccount" && to.name !== "policy") {
          this.checkNotification();
        } else {
          clearInterval(this.intervalBookingNotification);
          this.intervalBookingNotification = null;
        }
      },
    },
    methods: {
      async checkBooking() {
        const role = getRole();
        if (role != undefined) {
          const booking = await this.getBooking();
          if (role === this.$role.DRIVER) {
            if (booking && booking.status != "COMPLETED") {
              this.$router.replace({
                name: "requestRecharge",
                params: { id: booking.timeSlotId._id },
              });
              return;
            }
          }
        }
      },
      getLatestBooking(listBooking) {
        if (listBooking.length > 0) {
          var latestBooking = listBooking[0];
          var latestBookingCompletedAt = new Date(latestBooking.completedAt);
          for (const booking of listBooking) {
            const bookingCompletedAt = new Date(booking.completedAt);
            if (bookingCompletedAt > latestBookingCompletedAt) {
              latestBookingCompletedAt = bookingCompletedAt;
              latestBooking = booking;
            }
          }
          return latestBooking;
        }
        return null;
      },
      async getBookingsWaitingForRating() {
        const response = await this.$http.get(
          "bookings/rating"
        );
        const bookingsWaitingForRating = response.data.data
        if (bookingsWaitingForRating.length > 1) {
          const firstLatestBooking = getLatestBooking(bookingsWaitingForRating);
          const bookingsWaitingForRatingWithoutFirstLatestBooking =
            bookingsWaitingForRating.filter(
              (item) => item === firstLatestBooking
            );
          const secondLatestBooking = getLatestBooking(
            bookingsWaitingForRatingWithoutFirstLatestBooking
          );
          return [firstLatestBooking, secondLatestBooking];
        } else {
          return bookingsWaitingForRating;
        }
      },
      async getBooking() {
        const startDay = this.$moment().startOf("week").weekday(1).hours(5).minutes(59).format();
        const endDay = this.$moment().endOf("week").weekday(8).hours(5).minutes(59).format();
        const bookingsResponse = await this.$http.get("bookings", {
          params: { startAt: startDay, endAt: endDay },
        });
        const listBooking = bookingsResponse.data.data;
        if (getRole() === this.$role.DRIVER) {
          const countChargeResponse = await this.$http.patch(
            "bookings/countCharge",
            { startAt: startDay, endAt: endDay }
          );
          this.$store.state.countCharged =
            countChargeResponse.data.data.countCharge;
          this.$store.state.canOrder = countChargeResponse.data.data.canOrder;
          this.$store.state.bookingsToRate =
            await this.getBookingsWaitingForRating();
        }
        const hasBooking = listBooking.find(
          (booking) =>
            [
              this.$status.PENDING,
              this.$status.ACCEPTED,
              this.$status.STARTED,
              this.$status.COMPLETED,
            ].includes(booking.status) &&
            this.$moment(booking.createdAt).format("YYYY-MM-DD") ===
              this.$moment(new Date()).format("YYYY-MM-DD")
        );
        if (hasBooking) {
          this.$store.state.bookingSelected = hasBooking;
          return hasBooking;
        } else return false;
      },
      checkNotification() {
        if (getRole() === this.$role.TEAM_MEMBER && this.intervalBookingNotification == null) {
          this.getBookingNotification();
          this.intervalBookingNotification = setInterval(() => {
            this.getBookingNotification();
          }, 10 * 1000);
        }
      },
      closeModal() {
        this.bookingNotification = undefined;
      },
      getBookingNotification() {
        if (this.bookingNotification != undefined) return;
        if (this.bookingNotificationRequestInProgress == true) return;
        this.bookingNotificationRequestInProgress = true;
        this.$http.get(`bookings/notification`).then(({ data }) => {
          if (data.data != undefined) {
            if (
              data.data.booking != undefined &&
              data.data.notificationType != undefined &&
              data.data.notificationType != "NONE" &&
              data.data.booking._id != undefined
            ) {
              this.bookingNotification = data.data;
            }
            this.bookingNotificationRequestInProgress = false;
          }
        });
      },
    },
  };
</script>
