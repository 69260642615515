<template>
    <v-layout fill-height>
      <!-- <NavComponent :toggle="menu"></NavComponent> -->
      <HeaderComponent @toggle-menu="menu = !menu"></HeaderComponent>
      <v-main class="p-4">
          <router-view></router-view>
      </v-main>
    </v-layout>
</template>
<script>
import HeaderComponent from "@/components/Header";
import NavComponent from "@/components/Nav";
export default {
  name: "DefaultLayout",
  components: { HeaderComponent, NavComponent },
  data() {
    return {
      menu: true
    }
  },
  mounted() {
    if (this.$isMobile()) this.menu = false
  },
};
</script>