export const ROLE = {
    DRIVER: 'DRIVER',
    TEAM_MEMBER: 'TEAM_MEMBER',
};

export const STATUS = {
    ACTIVATED: 'ACTIVATED',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
    ACCEPTED: 'ACCEPTED',
    STARTED: 'STARTED',
    REFUSED: 'REFUSED',
    COMPLETED: 'COMPLETED',
    
    AVAILABLE: 'AVAILABLE',
    BOOKED: 'BOOKED',
    BLOCKED: 'BLOCKED',
    CLOSED: 'CLOSED',
    TIMED_OUT: 'TIMED_OUT',
};