<template>
  <v-dialog v-model="dialogCancelled" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h5">
        {{ option.title }}
      </v-card-title>
      <v-card-text class="pre" >
        <span v-html="`${option.content}`"></span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="hidePopup" v-show="option.cancelText">
          {{ option.cancelText }}
        </v-btn>
        <v-btn color="primary" text @click="onAction(option.onAction)">
          {{ option.actionText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Dialog",
  data() {
    return {
      dialogCancelled: false,
      option: {
        title: "",
        content: "",
        cancelText: "",
        actionText: "",
        onAction: null,
      },
    };
  },
  methods: {
    showPopup(option) {
      this.dialogCancelled = true;
      this.option = { ...option };
    },
    hidePopup() {
      this.dialogCancelled = false;
    },
    onAction(callback = () => {}) {
      callback()
      this.hidePopup()
    },
  },
};
</script>