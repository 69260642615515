export const getRole = () => {
    const user = getUser()
    return user?.role
}

export const getUser = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    return user
}

export const getLocalSavedParkingUsed = () => {
    return localStorage.getItem('lastParkingSaved')
}

export const setLocalSavedParkingUsed = (parking) => {
    localStorage.setItem('lastParkingSaved', parking)
}

export const setUser = (user) => {
    localStorage.setItem('user', user)
}
