<template>
  <v-snackbar
    :color="color"
    :timeout="timer"
    v-model="showSnackbar"
    bottom
    right
  >
    <v-icon left>{{ icon }}</v-icon
    >{{ message }}
  </v-snackbar>
</template>
  
  <script>
export default {
  name: "vToast",
  data() {
    return {
      showSnackbar: false,
      message: "",
      color: "secondary",
      icon: "",
      timer: 3000,
    };
  },
  methods: {
    show(data) {
      this.timer = data?.timer || 3000;
      this.message = data?.message || 'missing "message".';
      switch (data?.type) {
        case "success":
          this.color = "success";
          this.icon = "mdi-check";
          break;
        case "error":
          this.color = "red accent-2";
          this.icon = "mdi-close-circle-outline";
          break;
        case "info":
          this.color = "primary";
          this.icon = "mdi-information-outline";
          break;
      }
      this.showSnackbar = true;
    },
  },
};
</script>