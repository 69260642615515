import axios from 'axios';
//Create instance of axios
const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

const getToken = () => localStorage.getItem("jwt")
    ? localStorage.getItem("jwt")
    : null;

const getAuthorizationHeader = () => `Bearer ${getToken()}`;
instance.interceptors.request.use((config) => {
    config.headers['Authorization'] = getAuthorizationHeader()
    return config;
}, (error) => {
    return Promise.reject(error)
});

instance.interceptors.response.use(
    response => response,
    error => {
        // console.log('error', error);
        if (error?.response?.status === 401) {
            localStorage.removeItem("user");
            localStorage.removeItem("jwt");
            localStorage.removeItem("location");
            window.location.href = '/login';
        }
        return Promise.reject(error);
    });
export default instance;
