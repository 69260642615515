import Vue from 'vue'
import Vuex from 'vuex'

import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex)


const initState = () => {
  return {
    slotSelected: null,
    bookingSelected: null,
    countCharged: 0,
    canOrder: true,
    bookingsToRate: [],
    locationSeleted: {
      location: null,
      parking: null,
      slot: null
    },
  }
}
export default new Vuex.Store({
  state: initState(),
  getters: {
    getField
  },
  mutations: {
    updateField,
    resetState(state) {
      Object.assign(state, initState())
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState')
    },
  },
  modules: {
  }
})
