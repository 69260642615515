<template>
  <v-navigation-drawer :value="toggle" app>
    <v-list dense nav>
      <v-list-item-group color="primary">
        <v-list-item class="my-2" disabled>
          <v-list-item-icon>
            <img id="logo" src="@/assets/logo.png" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              ><h1 style="color: #333">Click4Charge</h1></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          @click="$router.push(item.link)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <!-- <template v-slot:append>
      <div class="pa-2">
        <v-btn block> Logout </v-btn>
      </div>
    </template> -->
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "NavComponent",
  props: ["toggle"],
  data() {
    return {
      items: [
        { title: "List Recharge", icon: "mdi-calendar-multiselect-outline ", link: "/" },
      ],
      right: null,
    };
  }
};
</script>