<template>
  <v-app-bar app elevation="0" class="custom-header">
    <v-icon @click="onNavigateBack()" class="mr-1" v-show="canBack"
      >mdi-chevron-left</v-icon
    >
    <p
      class="title mb-0 ml-1 grey--text text--darken-4 font-weight-bold"
      v-if="role === $role.TEAM_MEMBER && (slotSelected || bookingSelected) && this.$route.name !== 'listRecharge'"
    >
      {{
        slotSelected?.timeSlot.slice(0, 5) ||
        bookingSelected?.timeSlotId.timeSlot.slice(0, 5)
        
      }}
      Slot
    </p>
    <v-img max-width="114" id="logo" src="@/assets/logo.png" v-else />
    <!-- <h3 class="ml-2">
      {{ $route.meta.title }}
    </h3> -->
    <v-spacer></v-spacer>
    <div outlined color="primary" dark class="elipsis pl-4">
      <span class="title font-weight-bold primary--text">
        {{ user.name }}
      </span>
    </div>
    <div class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" v-bind="attrs" v-on="on" class="ml-2"
            >mdi mdi-menu</v-icon
          >
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in menuItems" :key="index">
            <v-list-item-title
              style="cursor: pointer"
              @click="onMenuItemClick(item.title)"
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!-- <v-btn color="primary" @click="onLogout" class="ml-2">Logout</v-btn> -->
    <!-- <v-app-bar-nav-icon
      variant="text"
      @click.stop="$emit('toggle-menu')"
      v-if="$isMobile()"
    ></v-app-bar-nav-icon> -->
  </v-app-bar>
</template>
<script>
  import { getRole, getUser } from "@/utils/user";
  import { mapFields } from "vuex-map-fields";

  export default {
    name: "HeaderComponent",
    computed: {
      ...mapFields(["bookingSelected", "slotSelected"]),
      role() {
        return getRole();
      },
      user() {
        return getUser();
      },
      menuItems() {
        if (getRole() == "ADMIN") {
          return [
            { title: "Home" },
            { title: "Download"},
            { title: "Edit password" },
            { title: "Create account" },
            { title: "Accounts list" },
            { title: "Parkings list" },
            { title: "Logout" },
          ];
        } else {
          return [
            { title: "Home" },
            { title: "Edit password" },
            { title: "Logout" },
          ];
        }
      },
      canBack() {
        if (this.$route.name === "listRecharge") return false;
        if (
          (this.role === this.$role.DRIVER && !this.bookingSelected) ||
          this.role === this.$role.TEAM_MEMBER ||
          this.role === "ADMIN"
        )
          return true;
        return false;
      },
    },
    methods: {
      onNavigateBack() {
        if (getRole() === this.$role.TEAM_MEMBER) {
          this.slotSelected = null;
        }
        this.$router.back();
      },
      onLogout() {
        this.$vPopup.showPopup({
          title: "Logout confirmation",
          content:
            "Do you really want to logout?<br/><br/>Please go back or confirm the cancelation.",
          actionText: "Yes, Logout",
          cancelText: "No, go back",
          onAction: () => {
            this.$store.dispatch("resetState");
            this.$http.post("auth/logout").then((res) => {
              localStorage.removeItem("user");
              localStorage.removeItem("jwt");
              localStorage.removeItem("location");
              this.$router.replace("/login");
            });
          },
        });
      },
      async downloadBookingsData() {
        const role = getRole();
        if (role !== this.$role.TEAM_MEMBER && role !== this.$role.DRIVER) {
          const response = await this.$http.get(
            "bookings/csv"
          );
          if (response.data.data != undefined) {
            const now = new Date()
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data.data);
            anchor.target = '_blank';
            anchor.download = `c4c-bookings-${now.getFullYear() + '-' + now.getMonth() + '-' + now.getDay()}.csv`;
            anchor.click();
          }
        }
      },
      onMenuItemClick(title) {
        if (title == "Edit password" && this.$route.name != "editPassword") {
          this.$router.push({ name: "editPassword" });
        } else if (
          title == "Create account" &&
          this.$route.name != "createAccount"
        ) {
          this.$router.push({ name: "createAccount" });
        } else if (
          title == "Accounts list" &&
          this.$route.name != "accountsList"
        ) {
          this.$router.push({ name: "accountsList" });
        } else if (title == "Home" && this.$route.name != "listRecharge") {
          this.$router.replace({ name: "listRecharge" });
        } else if (
          title == "Parkings list" &&
          this.$route.name != "parkingsList"
        ) {
          this.$router.push({ name: "parkingsList" });
        } else if (title == "Logout") {
          this.onLogout();
        } else if (title == "Download") {
          this.downloadBookingsData();
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .custom-header {
    background-color: #fff !important;
    border-bottom: 1px solid $primary-blue;
  }
  .elipsis {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
</style>
